<template>
  <div>
    <div class="row page-title-header">
      <div class="col-12">
        <div class="page-header">
          <b-breadcrumb class="m-0">
            <b-breadcrumb-item
              :to="{
                name: 'DashboardHome',
              }"
            >
              <i class="fa fa-home"></i>
            </b-breadcrumb-item>

            <b-breadcrumb-item
              :to="{
                name: 'OrganizationHome',
              }"
            >
              組織資料設定
            </b-breadcrumb-item>
            <b-breadcrumb-item
              :to="{
                name: 'DailyActivityList',
              }"
              >{{ "每日活動列表" }}
            </b-breadcrumb-item>
            <b-breadcrumb-item active
              >{{
                isReadOnly ? "查看每日活動" : isEditing ? "編輯每日活動" : "新增每日活動"
              }}
            </b-breadcrumb-item>
          </b-breadcrumb>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <b-overlay :show="showLoading">
          <b-card>
            <div class="row d-flex mb-4 mb-xl-2 justify-content-between">
              <h4 class="col-12 col-xl-6 mb-2 mb-xl-0 font-weight-bold">
                {{
                  isReadOnly ? "查看每日活動" : isEditing ? "編輯每日活動" : "新增每日活動"
                }}
              </h4>
              <div class="col-12 col-xl-8 mt-3">
                <validation-error-alert
                  v-if="validationErrors"
                  :errors="validationErrors"
                ></validation-error-alert>
                  <b-form-group
                    label-cols="12"
                    label-cols-lg="2"
                    label-size="sm"
                    label="通路"
                  >
                    <b-form-select
                      v-model="dailyActivity.merchant_id"
                      :options="merchants"
                      :disabled="isReadOnly"
                    ></b-form-select>
                    <b-form-invalid-feedback :state="!v$.dailyActivity.merchant_id.$error">
                      此欄位為必填
                    </b-form-invalid-feedback>
                  </b-form-group>
                  <b-form-group
                    label-cols="12"
                    label-cols-lg="2"
                    label-size="sm"
                    label="會員"
                  >
                    <b-form-select
                      v-model="dailyActivity.customer_id"
                      :options="customers"
                      :disabled="isReadOnly"
                    ></b-form-select>
                    <b-form-invalid-feedback :state="!v$.dailyActivity.merchant_id.$error">
                      此欄位為必填
                    </b-form-invalid-feedback>
                  </b-form-group>
                  <b-form-group
                    label-cols="12"
                    label-cols-lg="2"
                    label-size="sm"
                    label="活動時間"
                  >
                  <div class="d-flex">
                      <div class="w-50 mr-2">
                        <datepicker
                          v-model="dailyActivity.activity_at"
                          bootstrap-styling
                          format="yyyy-MM-dd"
                          :language="zh"
                          :disabled="isReadOnly"
                        ></datepicker>
                      </div>
                      <div class="w-50">
                        <vue-timepicker
                          format="HH:mm"
                          v-model="activityTime"
                          :input-class="['form-control']"
                          :hide-clear-button="true"
                          :disabled="isReadOnly"
                        ></vue-timepicker>
                      </div>
                    </div>
                    <b-form-invalid-feedback :state="!v$.dailyActivity.activity_code.$error">
                      此欄位為必填
                    </b-form-invalid-feedback>
                  </b-form-group>

                  <b-form-group
                    label-cols="12"
                    label-cols-lg="2"
                    label-size="sm"
                    label="代碼"
                  >
                    <b-form-input
                      class="mb-2 mr-sm-2 mb-sm-0"
                      type="text"
                      :state="v$.dailyActivity.activity_code.$error ? false : null"
                      v-model="dailyActivity.activity_code"
                      :readonly="isReadOnly"
                    ></b-form-input>
                    <b-form-invalid-feedback :state="!v$.dailyActivity.activity_code.$error">
                      此欄位為必填
                    </b-form-invalid-feedback>
                  </b-form-group>
                  <b-form-group
                    label-cols="12"
                    label-cols-lg="2"
                    label-size="sm"
                    label="活動值"
                  >
                    <b-form-input
                      class="mb-2 mr-sm-2 mb-sm-0"
                      type="text"
                      :state="v$.dailyActivity.activity_code.$error ? false : null"
                      v-model="dailyActivity.activity_value"
                      :readonly="isReadOnly"
                    ></b-form-input>
                    <b-form-invalid-feedback :state="!v$.dailyActivity.activity_code.$error">
                      此欄位為必填
                    </b-form-invalid-feedback>
                  </b-form-group>
                  <b-form-group
                    label-cols="12"
                    label-cols-lg="2"
                    label-size="sm"
                    label="活動備忘"
                  >
                    <b-form-textarea
                      class="mb-2 mr-sm-2 mb-sm-0"
                      type="text"
                      :state="v$.dailyActivity.activity_memo.$error ? false : null"
                      v-model="dailyActivity.activity_memo"
                      :readonly="isReadOnly"
                    ></b-form-textarea>
                    <b-form-invalid-feedback :state="!v$.dailyActivity.activity_memo.$error">
                      此欄位為必填
                    </b-form-invalid-feedback>
                  </b-form-group>
              </div>
            </div>
            <div class="d-flex justify-content-center">
              <b-button
                class="mr-3"
                @click="handleCancel"
                variant="outline-danger"
                >返回
              </b-button>
              <b-button
                v-if="!isReadOnly"
                @click="handleSubmit"
                variant="success"
                >儲存</b-button
              >
            </div>
          </b-card>
        </b-overlay>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters } from "vuex"
import useVuelidate from "@vuelidate/core"
import { required, alphaNum, numeric } from "@vuelidate/validators"
import dailyActivityApi from "../../../apis/daily-activity"
import merchantApi from "../../../apis/merchant"
import Datepicker from "vuejs-datepicker"
import { zh } from "vuejs-datepicker/dist/locale"
import customerApi from "@/apis/customer"
import VueTimepicker from "vue2-timepicker"
import moment from "moment"

export default {
  setup: () => ({ v$: useVuelidate() }),
  components: { Datepicker, VueTimepicker },
  validations() {
    return {
      dailyActivity: {
        activity_code: { alphaNum },
        merchant_id: { required },
        customer_id: {} ,
        activity_value: { numeric },
        activity_memo: {},

      }
    };
  },

  data() {
    return {
      zh,
      showLoading: false,
      isEditing: true,
      validationErrors: null,
      merchants: [{ value: null, text: "請選擇" }],
      customers: [{ value: null, text: "請選擇" }],
      dailyActivity: {
        activity_code: null,
        merchant_id: null,
        customer_id: null,
        activity_at: null,
        activity_value: null,
        activity_memo: null,
      },
    };
  },

  computed: {
    activityTime: {
      get() {
        return moment(this.dailyActivity.activity_at).format("HH:mm");
      },
      set(value) {
        this.dailyActivity.activity_at = moment(this.dailyActivity.activity_at).format("YYYY-MM-DD") + " " + value + ":00";
        console.log(this.dailyActivity.activity_at)
      },
    },
    ...mapState("general", {
      organization: (state) => state.organization,
    }),
    ...mapGetters("route", ["routeQuery"]),

    isReadOnly() {
      return this.$route.name === "DailyActivityView";
    },
  },
  async mounted() {
    await this.fetchMerchants();
    await this.fetchCustomers();
    if (!this.$route.params.activity_id) {
      // this.clearDailyActivity();
      this.isEditing = false;
    } else {
      this.fetchDailyActivity();
      this.isEditing = true;
    }
  },
  methods: {
    async fetchCustomers() {
      this.loading = true
      let params = {}
      params.per_page = 1000
      params.page = this.page
      const { data } = await customerApi.getLineMerchantCustomers(params)
      this.customers = data.data
      // 這個做預留 就不用一直重複打
      this.customers = [
        { value: null, text: "請選擇" },
        ...data.data.map((customer) => {
          return {
            value: customer.id,
            text: `${customer.name}`,
          };
        }),
      ];
      this.lastPage = data.meta.last_page
      this.loading = false
    },
    async fetchMerchants() {
      const { data } = await merchantApi.list();
      this.merchants = [
        { value: null, text: "請選擇" },
        ...data.data.map((merchant) => {
          return {
            value: merchant.id,
            text: `${merchant.name} (${merchant.type_name})`,
          };
        }),
      ];
    },
    async fetchDailyActivity() {
      this.showLoading = true;
      const response = await dailyActivityApi.getDailyActivity(
        this.organization,
        this.$route.params.activity_id
      );
      this.dailyActivity = response.data.data;
      this.showLoading = false;
    },

    clearDailyActivity() {
      this.dailyActivity = {
        activity_code: null,
        merchant_id: null,
        customer_id: null,
        activity_at: null,
        activity_value: null,
        activity_memo: null,
      };
    },
    async handleSubmit() {
      const result = await this.v$.$validate();
      if (!result) return;

      try {
        if (this.isEditing) {
          let response = await dailyActivityApi.updateDailyActivity(
            this.organization,
            this.dailyActivity
          );
          if (response.status && response.status === 200) {
            this.$swal.fire({
              title: "更新成功",
              type: "success",
            });

            this.$router.push({ name: "DailyActivityList", query: this.routeQuery });
          }
        } else {
          let response = await dailyActivityApi.storeDailyActivity(this.dailyActivity);

          if (response.status && response.status === 200) {
            this.$swal.fire({
              title: "新增成功",
              type: "success",
            });

            this.$router.push({ name: "DailyActivityList", query: this.routeQuery});
          }
        }
      } catch (error) {
        if (error.response.status === 422 && error.response.data.message) {
          this.validationErrors = error.response.data.message;
        } else {
          this.$swal.fire({
            title: this.isEditing ? "更新失敗" : "新增失敗",
            type: "error",
          });
        }
      }
    },
    handleCancel() {
      this.$router.push({ name: "DailyActivityList", query: this.routeQuery});
    }
  },
};
</script>
