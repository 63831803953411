import https from "./https"
import store from "@/store"

const dailyActivityApi = {
  getDailyActivities (organization, params) {
    return https.get(`admin/organizations/${organization.id}/daily-activities`, { params })
  },
  getDailyActivity (organization, dailyActivityId) {
    return https.get(`admin/organizations/${organization.id}/daily-activities/${dailyActivityId}`)
  },
  updateDailyActivity (organization, params) {
    return https.put(`admin/organizations/${organization.id}/daily-activities/${params.id}`, params)
  },
  removeDailyActivity (organization, params) {
    const url = 'admin/organizations/' + organization.id + '/daily-activities/' + params.id;
    return https.delete(url)
  },
  storeDailyActivity (params) {
    const organization = store.state.general.organization
    const url = 'admin/organizations/' + organization.id  + '/daily-activities'

    return https.post(url, params)
  }
}

export default dailyActivityApi
